import { useState } from 'react';
import DatePicker from 'react-datepicker';

import { useMessages } from '../../context/messagesContext';

import 'react-datepicker/dist/react-datepicker.css';

function CollapsedFilters({ objectLabel, searchString }) {
	const searchValue = objectLabel === 'Truck' && searchString !== '' ? <> for <strong>{ searchString }</strong></> : null;

	return (
		<>
			Searching for <strong>{ objectLabel }</strong>{ searchValue }
		</>
	);
}

export default function Filters({
	searchString, setSearchString,
	objectFilter, setObjectFilter,
	dateRange, setDateRange,
	undeliveredFilter, setUndeliveredFilter,
	filterMessages
}) {
	const { objectsAndModels } = useMessages();

	const [ isExpanded, setExpanded ] = useState( false );

	const updateDateRange = ( field, val ) => {
		setDateRange({ ...dateRange, [ field ]: val });
	};

	const resetState = () => {
		setObjectFilter( objectsAndModels[ 0 ] );
		setDateRange({ from: null, to: null });
		setSearchString( '' );
	};

	return (
		<div className="filters">
			<div className="filters__inner">
				<button className="filters__toggle  link--highlight" onClick={ () => setExpanded( isExpanded ? false : true )}>{ isExpanded ? 'Hide' : 'Show' } search criteria</button>
				{
					!isExpanded ?
						<CollapsedFilters objectLabel={ objectFilter.label } searchString={ searchString } setExpanded={ setExpanded } />
					: (
						<>
							<div className="filters__row">
								<div className="filters__full">
									<label htmlFor="search-string">Search</label>
									<input id="search-string" type="text" placeholder="Enter a Batch ID or Test ID" value={ searchString } onChange={ ( event ) => setSearchString( event.target.value ) } />
								</div>
							</div>

							<div className="filters__row">
								<div className="filters__full">
									<label>Within these dates</label>
								</div>

								<div className="filters__split">
									<div>
										<DatePicker selected={ dateRange.from } onChange={ date => updateDateRange( 'from', date ) } />
									</div>

									<span className="filters__to">to</span>

									<div>
										<DatePicker selected={ dateRange.to } onChange={ date => updateDateRange( 'to', date ) } />
									</div>
								</div>
							</div>

							<div className='filters__row'>
								<div className="filters__checkbox">
									<input type='checkbox' id='delivered-filter' value={undeliveredFilter} onChange={(e) => {
										setUndeliveredFilter(e.target.checked)}
									 } />
									<label htmlFor="delivered-filter">Show only undelivered messages?</label>
								</div>
							</div>

							<div className="filters__row">
								<button className="button--default" onClick={ () => filterMessages() }>Refine results</button>
								<button className="link--highlight" onClick={ () => resetState() }>Reset filters</button>
							</div>
						</>
					)
				}
			</div>
		</div>
	);
}
