import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

export function useRoles() {
  const { accounts } = useMsal();

  const [ roles, setRoles ] = useState([]);
  const [ name, setName ] = useState(undefined);

  function checkAccess( allowedRoles = []) {
    let rolesToCheck = [];

    if (Array.isArray(allowedRoles)) {
      rolesToCheck = allowedRoles;
    }
    else {
      rolesToCheck = [allowedRoles];
    }

    return roles.some(role => rolesToCheck.includes(role));
  }

  useEffect(() => {
    if (accounts.length) {
      const thisAccount = accounts[0];

      if (!thisAccount?.idTokenClaims?.roles) {
        setRoles(['Unauthorized'])
        setName(null);

        return;
      }

      const theseRoles = thisAccount.idTokenClaims.roles;

      setRoles(theseRoles);
      setName(thisAccount.name);
    }
  }, [accounts])

  return { roles, name, checkAccess }
}