import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {FaArrowAltCircleRight, FaCheckCircle, FaExclamation, FaEye} from 'react-icons/fa';
import ReactLoading from 'react-loading';

import {getTestDetails, republishTest, republishBatch} from '../../api'
import Modal from "react-modal";
import JsonViewer from "../JsonViewer";
import {useRoles} from "../../context/useRoles";
import {roles} from "../../constants/roles";

const getStatusCodeStyles = (statusCode) => {
  const baseStyle = { fontWeight: 'bold' }
  if (statusCode >= 200 && statusCode < 300)
    return { color: 'green', ...baseStyle };
  if (statusCode >= 400 && statusCode < 500)
    return { color: 'orange', ...baseStyle };
  if (statusCode >= 500 && statusCode < 600)
    return { color: 'red', ...baseStyle };
}

export default function TestSubmissionsTable({ eventId, hasTestData, hasBatchData}) {
  const [ isLoading, setIsLoading ] = useState(false);
	const [ testDetails, setTestDetails ] = useState([]);
  const [ republishingState, setRepublishingState ] = useState({
    type: '',
    isRepublishing: false,
    isModalOpen: false,
  })
  const [ isResponseModalOpen, setIsResponseModalOpen ] = useState(false);
  const [ selectedResponse, setSelectedResponse ] = useState(false);
  const { checkAccess } = useRoles();

	const fetchTestDetails = async () => {
    setIsLoading(true);
		const response = await getTestDetails(eventId);
		setTestDetails(response);
    setIsLoading(false);
	}

  const openResponse = (id) => {
    console.log(id)
    const selectedTest = testDetails.find(td => td.Id === id);
    if (!selectedTest?.Response) return;
    setSelectedResponse(selectedTest.Response);
    setIsResponseModalOpen(true);
  }

  const triggerRepublish = async () => {
    setRepublishingState({
      type: 'Test',
      isModalOpen: true,
      isRepublishing: true,
      });
    try {
      await republishTest(eventId);
      const response = await getTestDetails(eventId)
      setTestDetails(response);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setRepublishingState({
        type: '',
        isRepublishing: false,
        isModalOpen: false
      });
    }
  }

  const triggerBatchRepublish = async () => {
    setRepublishingState({
      type: 'Batch',
      isModalOpen: true,
      isRepublishing: true,
    });
    try {
      await republishBatch(eventId);
      const response = await getTestDetails(eventId)
      setTestDetails(response);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setRepublishingState({
        type: 'Batch',
        isModalOpen: false,
        isRepublishing: false,
      });
    }
  }

  useEffect(() => {
    fetchTestDetails();
  }, [])

  return (
    <div style={{ marginBottom: '15px' }}>
      <div className='object-details__actions'>
        <h3 className="heading--lvl-3">Driver Check Submissions</h3>
        {checkAccess([roles.admin]) && (
          <div>
            { hasTestData && (
              <button className='button--default' onClick={() => setRepublishingState({
                type: 'Test',
                isModalOpen: true,
              })}>Republish DriverCheck Result</button>
            )}
            { hasBatchData && (
              <button className='button--default' onClick={() => setRepublishingState({
                type: 'Batch',
                isModalOpen: true
              })}>Republish LIMS Batch Result
              </button>
            )}
          </div>
        )}
      </div>

      {isLoading && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <ReactLoading type={'spin'} color={'#c7c7c7'} height={32} width={32}/>
        </div>
      )}

      <table style={{ marginBottom: '70px'}} className='details-table'>
        <thead>
        <tr>
          <td>Timestamp</td>
          <td>Status Code</td>
          <td>Duration</td>
          <td>Is Delivered?</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
            {testDetails.map(e => (
              <tr key={e.Id}>
                <td className='details-table__cell'>
                  <p style={{margin: 0}}>{dayjs(e.CreatedOn).format('YYYY-MM-DD HH:mm:ss')}</p>
                </td>
                <td className='details-table__cell'>
                  <p style={{margin: 0, ...getStatusCodeStyles(e.StatusCode)}}>{e.StatusCode}</p>
                </td>
                <td className='details-table__cell'>
                  <p style={{ margin: 0 }}>{e.DurationMs}ms</p>
                </td>
                <td className='details-table__cell'>
                  {e.IsDelivered ? <FaCheckCircle color='green'/> : <FaExclamation color='red'/>}
                </td>
                <td className='details-table__cell' style={{ textAlign: 'right' }}>
                  <FaEye onClick={() => openResponse(e.Id)} size={25} style={{cursor: 'pointer', margin: '0 1rem'}} />
                  {e.SentryId && (
                    <FaArrowAltCircleRight
                      onClick={() => window.open(`https://acumium-organization.sentry.io/issues?query=${e.SentryId}`)}
                      size={25} style={{cursor: 'pointer', margin: '0 1rem'}}/>
                  )}
                </td>
              </tr>
              ))}
        </tbody>
      </table>
      <Modal
        isOpen={isResponseModalOpen}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
        onRequestClose={() => setIsResponseModalOpen(false)}
        contentLabel="Driver Check Response">
        <JsonViewer jsonObject={selectedResponse} />
      </Modal>
      <Modal
        isOpen={republishingState.isModalOpen}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '75%'
          }
        }}
        onRequestClose={() => setRepublishingState({ type: '', isModalOpen: false, isRepublishing: false })}
        contentLabel={republishingState.type === 'Test' ? "Republish Test Modal" : "Republish Batch Modal"}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
          <div>
            {republishingState.type === 'Test' && (
              <>
                <h3 style={{textAlign: 'center'}}>Republish DriverCheck Result</h3>
                <p>You are going to republish this DriverCheck Test Result to DriverCheck.</p>
              </>
            )}
            {republishingState.type === 'Batch' && (
              <>
                <h3 style={{textAlign: 'center'}}>Republish LIMS Batch Result</h3>
                <p>You are going to republish this LIMS Batch Test Result. This will result in all DriverCheck Results being resubmitted for this batch. Note: this only republishes the original LIMS Batch Test Result, if there have been changes to the results in LIMS since this was originally published, you will need to re-queue in LIMS instead.</p>
              </>
            )}
            <p>Are you sure you wish to proceed?</p>
          </div>
          <div>
            <button className='button--default' onClick={() => republishingState.type === 'Test' ? triggerRepublish() : triggerBatchRepublish()}>
              {republishingState.isRepublishing && 'Republishing...'}
              {!republishingState.isRepublishing && 'Yes'}
            </button>
            <button className='link--highlight' onClick={() => setRepublishingState({ type: '', isModalOpen: false, isRepublishing: false })}>No</button>
          </div>
        </div>

      </Modal>
    </div>
  )
}