import { LogLevel } from '@azure/msal-browser';

import { authRedirectUri, authAuthority, authClientId } from './lib/env';

const ua = window.navigator.userAgent;
const msie = ua.indexOf( 'MSIE ' );
const msie11 = ua.indexOf( 'Trident/' );
const msedge = ua.indexOf( 'Edge/' );
const firefox = ua.indexOf( 'Firefox' );

const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
	auth: {
		clientId: authClientId,
		authority: authAuthority,
		redirectUri: authRedirectUri,
		postLogoutRedirectUri: '/',
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: isIE || isEdge || isFirefox
	},
	system: {
		loggerOptions: {
			loggerCallback: ( level, message, containsPii ) => {
				if ( containsPii ) {
					return;
				}

				switch ( level ) {
					case LogLevel.Error:
						console.error( message );
						return;
					case LogLevel.Info:
						console.info( message );
						return;
					case LogLevel.Verbose:
						console.debug( message );
						return;
					case LogLevel.Warning:
						console.warn( message );
						return;
					default:
						return;
				}
			}
		}
	}
};

export const loginRequest = {
	scopes: ['api://d4da5f32-0142-4b28-aa63-c8e01075e2c3/drivers_check_api']
};
