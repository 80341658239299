import logo from '../static/omega-logo-blue.svg';

export default function Header({ pageTitle, breadcrumbs, summary }) {
	return (
		<header className="Header">
			<img src={ logo } className="omega-logo" alt="Omega Labs Logo" />

			{ breadcrumbs }

			<div className="header-wrapper">
				<h1 className="heading-lvl1 heading-divider">{ pageTitle }</h1>

				{ summary }
			</div>
		</header>
	);
}
