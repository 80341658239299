import { useMemo } from 'react';
import dayjs from 'dayjs';
import { FaCheckCircle, FaExclamation } from "react-icons/fa";


export function GetColumns() {
	const columns = useMemo( () => [
		{
			Header: 'LAN ID',
			accessor: 'DisplayId',
		},
		{
			Header: 'Reference ID',
			accessor: 'BatchDisplayId'
		},
		{
			Header: 'Timestamp UTC',
			accessor: 'CreatedOn',
			Cell: ({ row }) => {
				return <>{ dayjs( row.original.CreatedOn ).format( 'YYYY-MM-DD HH:mm:ss' ) }</>;
			}
		},
		{
			Header: 'Is Delivered?',
			accessor: "IsDelivered",
			disableSortBy: true,
			Cell: ({ row }) => (
				<span style={{ display: 'flex', justifyContent: 'center' }}>
					{ row.original.IsDelivered ? <FaCheckCircle color='green' /> : <FaExclamation color='red' /> }
				</span>
			)
		},
		{
			id: 'expander',
			disableSortBy: true,
			Header: () => null,
			Cell: ({ row }) => (
				<span { ...row.getToggleRowExpandedProps() }>
					{ row.isExpanded ? <button className="link--highlight">Close</button> : <button className="link--highlight">Details</button> }
				</span>
			)
		}
	], []);

	return columns;
}
