import React from 'react';

import TestSubmissionsTable from '../TestSubmissionsTable';
import XmlViewer from "../XmlViewer";

export default function Diff({ message, row, pageType }) {

	return (
		<div className="Diff">
			{
				pageType === 'list' && (
					<>
						<h2 className="heading--lvl-2 Diff__title">
							Driver Check Request Details
						</h2>

						<TestSubmissionsTable eventId={message.Id} hasTestData={!!message.Xml} hasBatchData={!!message.BatchXml}/>
					</>
				)
			}

			<XmlViewer xml={message.Xml} title="DriverCheck Request XML" />
			<XmlViewer xml={message.BatchXml} title="Batch Request XML" />

			<div className="Diff__links">
				<button className="link--highlight" {...row.getToggleRowExpandedProps()}>Close details</button>
			</div>
		</div>
	);
}
