import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from '../authConfig';

export const acquireAccessToken = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      throw new Error('Unauthorized: Unable to acquire token silently.');
    }
    const request = {
        ...loginRequest,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};