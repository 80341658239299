const pageSize = process.env.REACT_APP_PAGE_SIZE || '#{page_size}#';
const endpointUrl = process.env.REACT_APP_ENDPOINT_URL || '#{endpoint_url}#';
const authRedirectUri = process.env.REACT_APP_REDIRECT_URI || '#{redirect_uri}#';
const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID || '#{auth_client_id}#';
const authAuthority = process.env.REACT_APP_AUTH_AUTHORITY || '#{auth_authority}#';

export {
	pageSize,
	endpointUrl,
	authRedirectUri,
	authClientId,
	authAuthority
};
