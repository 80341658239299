import { createContext, useState, useContext, useRef, useCallback } from 'react';

import { getItemList } from '../api';
import objectsAndModels from '../lib/objectsAndModels';
import { GetColumns } from '../lib/tableHeaders';
import { pageSize as tablePageSize } from '../lib/env';

const MessagesContext = createContext();

function MessagesProvider({ children }) {
	const [ data, setData ] = useState( [] );
	const [ pageCount, setPageCount ] = useState( 0 );
	const [ loading, setLoading ] = useState( false );

	// fetch new messages
	const fetchIdRef = useRef( 0 );

	const fetchMessages = useCallback( async ({
		pageSize = tablePageSize,
		pageIndex = 0,
		sortBy = [],
		objectFilter = objectsAndModels[ 0 ],
		dateRange = { from: null, to: null },
		searchString = '',
		undeliveredFilter = false
	}) => {
		// store fetch id
		const fetchId = ++fetchIdRef.current;

		// set loading status
		setLoading( true );

		if ( fetchId === fetchIdRef.current ) {
			let searchParams = {
				pageNumber: pageIndex + 1,
				pageSize,
				onlyUndelivered: undeliveredFilter
			};

			if ( sortBy.length ) {
				searchParams.orderBy = sortBy[ 0 ].id;
				searchParams.orderByDirection = sortBy[ 0 ].desc ? 'DESC' : 'ASC';
			}

			if ( dateRange.from ) {
				searchParams.startDate = dateRange.from;
			}

			if ( dateRange.to ) {
				searchParams.endDate = dateRange.to;
			}

			if ( objectFilter ) {
				searchParams.objects = objectFilter.value;
			}

			if ( searchString ) {
				searchParams.searchString = searchString;
			}

			if ( undeliveredFilter ) {
				searchParams.onlyUndelivered = undeliveredFilter
			}

			let messages = await getItemList( searchParams );

			setData( messages.Entities );

			setPageCount( Math.ceil( messages.Count / pageSize ) );

			setLoading( false );
		}
	}, [] );

	// other stuff
	const columns = GetColumns();

	const value = {
		columns, objectsAndModels,
		fetchMessages,
		itemMessages: data,
		loading,
		pageCount,
	};

	return (
		<MessagesContext.Provider value={ value }>
			{ children }
		</MessagesContext.Provider>
	);
}

function useMessages() {
	const context = useContext( MessagesContext );

	if ( context === undefined ) {
		throw new Error( 'useMessages must be used within a MessagesProvider' );
	}

	return context;
}

export { MessagesProvider, useMessages };
