export default function Breadcrumbs({ links }) {
	return (
		<div className="Breadcrumbs">
			<div className="Breadcrumbs__links">
				<div className="Breadcrumbs__link-outer">
					<a className="Breadcrumbs__link  link--highlight" href="/">
						Home
					</a>
				</div>

				{
					links.map( ( link, index ) => (
						<div className="Breadcrumbs__link-outer" key={ index }>
							{ link.href ?
								<a className="Breadcrumbs__link  link--highlight" href={ link.href }>{ link.title }</a>
								:
								<span className="Breadcrumbs__link">{ link.title }</span>
							}
						</div>
					))
				}
			</div>
		</div>
	);
}
