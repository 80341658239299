import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTable, useExpanded, usePagination, useSortBy } from 'react-table';
import Modal from 'react-modal'

import Diff from '../Diff';
import Pagination from '../Pagination';
import TableLoading from '../TableLoading';
import Filters from '../Filters';

import { useMessages } from '../../context/messagesContext';

import { pageSize as tablePageSize } from '../../lib/env';

Modal.setAppElement( '#root' );

export default function Table({ pageType }) {
	const { messageId } = useParams();

	const filterMessages = () => {
		fetchMessages({ pageSize, pageNumber: 0, sortBy: [], objectFilter, dateRange, searchString });
		gotoPage( 0 );
	};

	const renderDetails = useCallback(
		({ row, pageType }) => (
			<>
				<Diff message={ row.original } row={ row } pageType={ pageType } />
			</>
		), []
	);

	const {
		columns,
		itemMessages,
		fetchMessages,
		loading,
		pageCount: controlledPageCount,
		objectsAndModels
	} = useMessages();

	const [ searchString, setSearchString ] = useState( '' );
	const [ objectFilter, setObjectFilter ] = useState( objectsAndModels[ 0 ] );
	const [ dateRange, setDateRange ] = useState({ from: null, to: null });
	const [ undeliveredFilter, setUndeliveredFilter ] = useState(false);

	const {
		// basic table stuff
		getTableProps,
		getTableBodyProps,
		headerGroups,

		// rows,
		page,
		prepareRow,
		visibleColumns,

		// pagination
		nextPage,
		previousPage,
		gotoPage,
		canPreviousPage,
		canNextPage,
		pageCount,

		// row expansion
		state: { /*expanded, */pageSize, pageIndex, sortBy }
	} = useTable(
		{
			columns,
			data: itemMessages,
			initialState: { pageIndex: 0, pageSize: tablePageSize },
			manualSortBy: true,
			manualPagination: true,
			pageCount: controlledPageCount,
			stateReducer: useCallback( ( newState, action, previousState ) => {
				// only allow one expanded row at a time
				if ( action.type === 'toggleRowExpanded' ) {
					const prevTokens = Object.keys( previousState.expanded );
					const newTokens = Object.keys( newState.expanded );

					if ( newTokens.length > 1 ) {
						const nextExpanded = {};

						for ( const t of newTokens ) {
							if ( !prevTokens.includes( t ) ) {
								nextExpanded[ t ] = true;
							}
						}

						return { ...newState, expanded: nextExpanded };
					}
				}

				return newState;
			}, [] )
		},
		useSortBy,
		useExpanded,
		usePagination,
	);

	useEffect( () => {
		fetchMessages({ pageIndex, pageSize, sortBy, objectFilter, dateRange, searchString: messageId || searchString, undeliveredFilter });
	}, [ fetchMessages, pageIndex, pageSize, sortBy, objectFilter, dateRange, searchString, messageId, undeliveredFilter ] );

	const getSortedClass = ( column => {
		if ( !column.canSort ) {
			return 'nosort';
		}

		if ( column.isSorted ) {
			return column.isSortedDesc ? 'sorted--desc' : 'sorted--asc';
		} else {
			return 'unsorted';
		}
	});

	return (
		<>
			{
				pageType === 'list' && (
					<Filters
						gotoPage={ gotoPage }
						objectFilter={ objectFilter }
						setObjectFilter={ setObjectFilter }
						dateRange={ dateRange }
						setDateRange={ setDateRange }
						filterMessages={ filterMessages }
						searchString={ searchString }
						setSearchString={ setSearchString }
						setUndeliveredFilter={ setUndeliveredFilter }
						undeliveredFilter={ undeliveredFilter }
					/>
				)
			}

			<div className="table-container">
				{ loading && <TableLoading /> }

				<table { ...getTableProps() } className="details-table">
					<thead>
						{
							headerGroups.map( headerGroup => (
								<tr { ...headerGroup.getHeaderGroupProps() }>
									{ headerGroup.headers.map( ( column, idx ) => (
										<th className="details-table__header" { ...column.getHeaderProps( column.getSortByToggleProps()) } key={ idx }>
											<span className={ `details-table__header-text ${ getSortedClass( column ) }` }>
												<span>{ column.render( 'Header' ) }</span>
											</span>
										</th>
									))}
								</tr>
							))
						}
					</thead>
					<tbody { ...getTableBodyProps() }>
						{
							page.map( ( row, idx ) => {
								prepareRow( row );

								const rowClass = row.isExpanded ? 'details-table__row--expanded' : '';

								return (
									<Fragment key={ idx }>
										<tr className={ rowClass }>
											{
												row.cells.map( ( cell, idx ) => {
													return <td { ...cell.getCellProps([ { className: `details-table__cell ${ cell.column.className }` } ]) } key={ idx }>{ cell.render( 'Cell' ) }</td>
												})
											}
										</tr>
										{
											row.isExpanded ? (
												<tr>
													<td colSpan={ visibleColumns.length }>
														{ renderDetails({ row, pageType }) }
													</td>
												</tr>
											) : null
										}
									</Fragment>
								)
							})
						}
					</tbody>
				</table>
			</div>

			<Pagination
				pageCount={ pageCount }
				canPreviousPage={ canPreviousPage }
				canNextPage={ canNextPage }
				nextPage={ nextPage }
				previousPage={ previousPage }
				gotoPage={ gotoPage }
				pageIndex={ pageIndex }
			/>
		</>
	);
}
