const objectsAndModels = [
	{
		'label': 'All tests/batches',
		'value': '*'
	},
	{
		'label': 'Truck',
		'value': 'Truck',
		'type': 'model'
	},
	{
		'label': 'Deal',
		'value': 'Deal',
		'type': 'model'
	},
	{
		'label': 'Account',
		'value': 'Account',
		'type': 'model'
	},
	{
		'label': 'Branch',
		'value': 'Branch',
		'type': 'model'
	}
];

export default objectsAndModels;
