import XMLViewer from "react-xml-viewer";
import React, {useState} from "react";
import {FaArrowDown, FaArrowUp, FaRegCopy} from "react-icons/fa";

const XmlViewer = ({ xml, title }) => {
  const [ isCollapsed, setIsCollapsed ] = useState(true);
  const [ copyIconStyle, setCopyIconStyle ] = useState({
    color: 'black',
    size: 25,
  });

  const onCopy = async () => {
    if (!xml) return;
    await navigator.clipboard.writeText(xml);
    setCopyIconStyle({
      color: 'green',
      size: 27,
    });
    setTimeout(() => setCopyIconStyle({
      color: 'black',
      size: 25
    }), 300);
  }

  return (
    <div>
      <h3
        className="heading--lvl-3"
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          userSelect: 'none'
        }}>
        {title}
        {isCollapsed
          ? <FaArrowDown/>
          : <FaArrowUp/>
        }
      </h3>
      {!isCollapsed && (
        <div>
          <div style={{backgroundColor: 'hsla(210,5%,92%,.5)', padding: '1rem', position: 'relative'}}>
            {xml ? (
              <>
                <div style={{position: 'absolute', right: '2rem'}}>
                  <FaRegCopy size={copyIconStyle.size} onClick={onCopy} color={copyIconStyle.color}
                             style={{cursor: 'pointer', transition: 'color height width'}}/>
                </div>
                <XMLViewer xml={xml} collapsible={false}/>
              </>
            ) : (
              <p style={{textAlign: 'center'}}>Data has been purged</p>
            )
            }
          </div>
        </div>
      )}
    </div>
  )
}

export default XmlViewer;