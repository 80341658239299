import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { loginRequest } from '../../authConfig';
import logo from "../../static/omega-logo-blue.svg";
import {useRoles} from "../../context/useRoles";
import {roles} from "../../constants/roles";

function ErrorComponent({ error }) {
	return <div>An authentication error occured: { error.errorCode }</div>
}

function Loading() {
	return <div>Authentication in progress...</div>
}

export default function ProtectedPage({ children }) {
	const { checkAccess } = useRoles();
	const authRequest = {
		...loginRequest
	};

	return (
		<MsalAuthenticationTemplate
			interactionType={ InteractionType.Redirect }
			authenticationRequest={ authRequest }
			errorComponent={ ErrorComponent }
			loadingComponent={ Loading }
		>
			{ checkAccess([roles.admin, roles.reader]) ? children : (
				<>
					<div style={{position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
						<img src={logo} className="omega-logo" alt="Omega Labs Logo"/>
						<p style={{ textAlign: 'center' }}>Unauthorized</p>
					</div>
				</>
			)}
		</MsalAuthenticationTemplate>
	);
}