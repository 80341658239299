import ProtectedPage from '../../components/ProtectedPage';

import Header from '../../layout/Header';
import Results from '../../layout/Results';
import Breadcrumbs from '../../components/Breadcrumbs';
import Summary from '../../components/Summary';

export default function Dashboard() {
	return (
		<ProtectedPage>
			<Header
				pageTitle="Tests"
				breadcrumbs={
					<Breadcrumbs links={ [] } />
				}
				summary={
					<Summary />
				}
			/>
				<Results pageType="list" />
			</ProtectedPage>
	);
}
