import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { getSummary } from '../../api';

dayjs.extend( isBetween );
dayjs.extend( isSameOrAfter );

export default function Summary() {
	const [ data, setData ] = useState({ today: 0, lastWeek: 0, lastMonth: 0 });
	const [ loading, setLoading ] = useState( false );


	useEffect( () => {
		async function fetchSummary() {
			setLoading( true );

			const summary = await getSummary();

			setData({
				today: summary.Today,
				todayDelivered: summary.TodayDelivered,
				lastWeek: summary.LastWeek,
				lastWeekDelivered: summary.LastWeekDelivered,
				lastMonth: summary.LastMonth,
				lastMonthDelivered: summary.LastMonthDelivered
			});

			setLoading( false );
		}

		fetchSummary();
	}, [] );

	return (
		<div className="object-details object-details--end">
			{ !loading && (
				<>
					<div className="object-details__item">
						<h3 className="object-details__label">Last 24 Hours</h3>

						<div className="object-details__value">{data.todayDelivered} / {data.today}</div>
					</div>

					<div className="object-details__item">
						<h3 className="object-details__label">Last 7 Days</h3>

						<div className="object-details__value">{data.lastWeekDelivered} / {data.lastWeek}</div>
					</div>

					<div className="object-details__item">
						<h3 className="object-details__label">Last 30 Days</h3>

						<div className="object-details__value">{data.lastMonthDelivered} / {data.lastMonth}</div>
					</div>
				</>
			)}
		</div>
	)
}
