import axios from 'axios';

import { endpointUrl, pageSize as ps } from '../lib/env';
import { TokenProvider } from '../utils';

axios.interceptors = axios.interceptors.request.use(async function (config) {
	const accessToken = await TokenProvider.acquireAccessToken();
	config.headers.Authorization = `Bearer ${accessToken}`

	return config;
});

async function getItemList({ pageNumber = 1, pageSize = ps, orderBy = 'CreatedOn', orderByDirection = 'DESC', searchString, startDate, endDate, domain, onlyUndelivered }) {
	const responseData = await axios.post( endpointUrl + "Tests", {
		pageSize: Number(pageSize),
		pageNumber,
		orderBy,
		orderByDirection,
		searchString,
		startDate,
		endDate,
		domain,
		onlyUndelivered
	});

	return responseData.data;
}

async function getSummary() {
	const responseData = await axios.get(endpointUrl + "TestsSummary");
	return responseData.data;
}

async function getTestDetails(id) {
	const responseData = await axios.get(endpointUrl + `TestDetails?id=${id}`);
	return responseData.data;
}

async function republishTest(id) {
	await axios.get(endpointUrl + `RepublishTest?id=${id}`);
}

export async function republishBatch(id) {
	await axios.get(endpointUrl + `RepublishBatch?id=${id}`);
}

export {
	getItemList,
	getSummary,
	republishTest,
	getTestDetails,
};