import ReactPaginate from 'react-paginate';

export default function Pagination({
	pageCount,
	gotoPage,
	canNextPage,
	canPreviousPage
}) {
	const handlePageChange = event => { gotoPage( event.selected ) };

	const getPagerClass = ( type, conditional ) => {
		let classNames = [ 'pagination__button', `pagination__button--${ type }` ];

		if ( !conditional ) {
			classNames.push( 'pagination__button--inactive' );
		}

		return classNames.join( ' ' );
	};

	return (
		<div className="pagination">
			<ReactPaginate
				breakLabel="..."
				nextLabel="Next"
				onPageChange={ handlePageChange }
				pageRangeDisplayed={ 3 }
				pageCount={ pageCount }
				previousLabel="Previous"
				renderOnZeroPageCount={ null }
				activeLinkClassName="pagination__button pagination__button--active"
				breakClassName="pagination__item"
				breakLinkClassName="pagination__button"
				containerClassName="pagination__buttons"
				pageClassName="pagination__item"
				pageLinkClassName="pagination__button"
				previousClassName="pagination__item"
				previousLinkClassName={ getPagerClass( 'previous', canPreviousPage ) }
				nextClassName="pagination__item"
				nextLinkClassName={ getPagerClass( 'next', canNextPage ) }
			/>
		</div>
	);
}
