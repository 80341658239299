import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import { MessagesProvider } from './context/messagesContext';

import Dashboard from './pages/Dashboard';

import './styles/global.scss';

function App({ pca }) {
	return (
		<MsalProvider instance={ pca }>
			<MessagesProvider>
				<div className="container">
					<Routes>
						<Route path="/" element={ <Dashboard /> } />
					</Routes>
				</div>
			</MessagesProvider>
		</MsalProvider>
	);
}

export default App;
